define(['app'], (app) => {

  const datePolyfill = () => {

    var component = {};
    component.app = app;

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
    const isSafariDesktop = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    let dateInputType = document.getElementById("dateOfBirth");  // change to type.
    let dateErrorMsg = document.getElementById("date-error-msg");  // change to type.
    let accountDetailsPageSubmit = document.getElementById("accountDetailsPageSubmit");
    let accountDetailsSubmitButton = document.querySelector("#accountDetailsPageSubmit button");
    let dobMessageForIe11 = document.getElementById("ie11-dob-message");

    // yyyy-mm-dd
    const dateRegExyyyyDashmmDashdd = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;
    // // day/month-year reg expression. 1-31 && deliniator 1-12 && deliniator 19xx-2xxx
    const dateRegExSlashesDayMonthYear = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    // month/year/day reg expression.
    const regexMonthSlashDaySlashYearSlash = /^(?:(0[1-9]|1[012])[\/.](0[1-9]|[12][0-9]|3[01])[\/.](19|20)[0-9]{2})$/;


    let dateInputTypeLengthCheck = (dateInputTypeValue) => {
      let dateInputTypeLength = dateInputTypeValue.length;
      if (dateInputTypeLength === 10) {
        return true;
      } else {
        return false;
      }
    }

    let geoRegExToUse;

    if (dateInputType.classList.contains('dmy')) {
      geoRegExToUse = dateRegExSlashesDayMonthYear;
    }

    // if date regex if mm/dd/yyyy (such as usa) change regex pattern used to validate date.
    if (dateInputType.classList.contains('mdy')) {
      geoRegExToUse = regexMonthSlashDaySlashYearSlash;
    }

    // if regex is china and such - yyyy-mm-dd

    if (dateInputType.classList.contains('ymd')) {
      geoRegExToUse = dateRegExyyyyDashmmDashdd;
    }


    // if geo regex passes, or date is formatted per server constrains ( dd-dd-yyyy )v
    let checkIfValidDate = (dateInputTypeValue) => {
      if (dateInputTypeValue.match(geoRegExToUse) || (dateInputTypeValue.match(dateRegExyyyyDashmmDashdd))) { // 11/10/1985
        return true;
      } else {
        return false;
      }
    }

    if (isIE11 || isSafariDesktop) {

      // show message in p tag which will indicate to end user that the user needs to enter thedate in dd/mm/yyyy format.

      dobMessageForIe11.classList.remove("is-hidden");

      let checkIfValidDateAndLength = (dateInputTypeValue) => {
        if ((checkIfValidDate(dateInputTypeValue)) && (dateInputTypeLengthCheck(dateInputTypeValue))) {
          dateErrorMsg.classList.add("is-hidden");
          accountDetailsSubmitButton.disabled = false;
          return true;
        } else {
          dateErrorMsg.classList.remove("is-hidden");
          accountDetailsSubmitButton.disabled = true;
          return false;
        }
      }

      // needs to be 20/11/1991 visually

      let createCorrectFormatForDate = (delineator, arrayIndex, dateInputTypeValue) => {
        if (dateInputTypeValue.length == arrayIndex) {
          dateInputType.value += delineator;
        }
      }

      // block certain chars from being entered to lock user into only being able to input deliniators

      dateInputType.addEventListener('keydown', function inputListener(e) {
        if (e.key === "/" || e.key === "." || e.key === "-") {
          e.preventDefault();
        }
      });

      // On keyup run deliniator.

      dateInputType.addEventListener('keyup', function inputListener(e) {
        let dateInputTypeValue = dateInputType.value;
        // only fire func if 0 to 9 pressed for date.
        if (e.key >= "0" && e.key <= "9") {
          // if format needs to be yyyy-mm-dd deliniate based on chars else dd/mm/yyyy
          if (dateInputType.classList.contains('ymd')) {
            createCorrectFormatForDate("-", 4, dateInputTypeValue);
            createCorrectFormatForDate("-", 7, dateInputTypeValue);
          } else {
            createCorrectFormatForDate("/", 2, dateInputTypeValue);
            createCorrectFormatForDate("/", 5, dateInputTypeValue);
          }
        }
        // if the date type value if not 0

        if (dateInputTypeValue != 0) {
          checkIfValidDateAndLength(dateInputTypeValue);
        }
      });


      // this produces simpleJavaDate yyyy-mm-dd to be converted in controller, to avoid back end changes.
      // if date is not yyyy-mm-dd execute string split on entered date.

      accountDetailsPageSubmit.addEventListener("submit", function (event) {
        if (dateInputType.value.length > 0) {
          // if the date does not match the date returned on the server format (which incidentially if china format)
          if (!dateInputType.value.match(dateRegExyyyyDashmmDashdd)) {
            let dateInputTypeValue2 = dateInputType.value;
            let dateInputTypeValueHello = dateInputTypeValue2.value;
            // clone value, conert to string
            let dateInputTypeValue2ToString = dateInputTypeValue2.toString();
            // no default can be set.
            let year;
            let month;
            let day;
            // if day month year format, re order on submit
            if (dateInputType.classList.contains('dmy')) {
              year = dateInputTypeValue2ToString.substring(6, 10);
              month = dateInputTypeValue2ToString.substring(3, 5);
              day = dateInputTypeValue2ToString.substring(0, 2);
            }
            if (dateInputType.classList.contains('mdy')) {
              year = dateInputTypeValue2ToString.substring(6, 10);
              month = dateInputTypeValue2ToString.substring(0, 2);
              day = dateInputTypeValue2ToString.substring(3, 5);
            }
            dateInputTypeValueHello = year + "-" + month + "-" + day;
            dateInputType.value = dateInputTypeValueHello;
          };
        };
      });


      // if date input is yyyy-mm-dd 
      component.checkIfValidDateAndLength = checkIfValidDateAndLength;
      component.createCorrectFormatForDate = createCorrectFormatForDate;

    };

    // outside of ie11 and safari function so chronium as apart of the unit tests will be able to get code coverage of this file.

    component.dateInputTypeLengthCheck = dateInputTypeLengthCheck;
    component.checkIfValidDate = checkIfValidDate;

    return component;
  };

  return datePolyfill;

});
